<template>
    <div>
        <div class="modal fade" id="itemProjectModal" tabindex="-1" aria-labelledby="itemProjectModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="itemProjectModalLabel" class="font-weight-bold">Add Item</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        
                        <CRow v-if="this.forecast_type == 'Paket'">
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Jumlah Paket</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <CInput type="number" id="PaketAmount" v-model="PaketAmount" class="font-weight-bold" />
                                <label id="errorPaketAmount" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Tipe Detail</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <v-select id="TypeDetail" class="pb-3" :options="TypeDetailData" v-model="TypeDetail" @input="onChangeTypeDetail"/>
                                <label id="errorTypeDetail" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow v-else>
                            <CCol sm="2">
                                <label class="form-label font-weight-bold">Tipe Detail</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol sm="4">
                                <v-select id="TypeDetail" class="pb-3" :options="TypeDetailData" v-model="TypeDetail" @input="onChangeTypeDetail"/>
                                <label id="errorTypeDetail" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>


                        <kendo-grid ref="grid"
                            :data-source="DataSource"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :pageable="true"
                            :columns="columns"
                            :selectable="SelectableValue"
                            :editable="EditableValue"
                            :resizable="true"
                            >
                        </kendo-grid>
                        <br/>

                        <CButton type="button" @click="saveClick()" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import proformaInvoiceServices from '../Script/ProformaInvoideDownPaymentScript';

export default {
    name: 'ItemProjectForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['saveItemProjectGrid'],
    mounted:  function () {
    },
    data: function () {
        return {
            DataSource: [],
            columns: [],
            ItemSchemaModel: {
                type_detail_name: { editable: false },
                product_code: { editable: false },
                item_name: { editable: false },
                stock: { type: "number", editable: false },
                uom: { editable: false },
                conv_amount: { type: "number", editable: false },
                price_retail: { type: "number", editable: false },
                price_project: { type: "number", editable: false },
                selected_qty : { type: "number", editable: true, validation: { min:0} },
                discount1: { type: "number", editable: false},
                discount2: { type: "number", editable: false},
                discount3: { type: "number", editable: false},
                discount4: { type: "number", editable: false},
                discount5: { type: "number", editable: false},
                discount_cash: { type: "number", editable: false},
                quantity: { type: "number", editable: true, validation: { min:0} }
            },
            PaketAmount : 0,
            type : '',
            forecast_type : '',
            TypeDetail: '',
            TypeDetailData: [],
            EditableValue : true,
            SelectableValue : true,
            ProjectId : '',
            PO : '',

            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
        }
    },
    methods: {
        async addClick(projectId, po, type, forecastType){ 
            var itemSchemaModel = this.ItemSchemaModel;

            if(forecastType == 'Paket'){
                this.EditableValue = false;
                this.SelectableValue = false;
                this.PaketAmount = 1;
            }
            else if (forecastType == 'Forecast 1'){
                this.EditableValue = false;
                this.SelectableValue = false;
            }
            else{
                this.EditableValue = true;
                this.SelectableValue = true;
            }
            
            this.forecast_type = forecastType;
            this.type = type;
            
            this.TypeDetailData = await proformaInvoiceServices.readTypeDetailQuery();
            this.TypeDetail = '';

            this.ProjectId = projectId;
            this.PO = po;
            
            this.DataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                id : projectId,
                                po : po,
                                typeDetailId : null
                            }
                            
                            return { 
                                query: proformaInvoiceServices.readItemQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrderableItemList == null){
                            return [];
                        }else{
                            return proformaInvoiceServices.fillSelectedQuantity(response.data.GetTransactionPurchaseOrderableItemList, forecastType);
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrderableItemList == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrderableItemList.length;
                        }
                    },
                    model: {
                        fields: itemSchemaModel
                    }
                }
            });

            this.columns = proformaInvoiceServices.columnsProject(type, forecastType)
            window.$('#itemProjectModal').modal('show');
        },
        saveClick(){
            var grid = this.$refs.grid.kendoWidget();
            var errorMessage = "";
            var dataItem = [];
            
            if(this.forecast_type == 'Forecast 1'){
                //Forecast 1
                var data = grid.dataSource._data;
                
                if(data.length > 0){
                    for (let i = 0; i < data.length; i++) {
                        var selected_qty = data[i].forcast_quantity;
                        dataItem.push(proformaInvoiceServices.itemDataObject(data[i], selected_qty, this.type)[0]);
                    }
                    this.$props.saveItemProjectGrid(dataItem);
                } 
            }
            else if(this.forecast_type == 'Forecast 2'){
                //Forecast 2
                var selectedItem = grid.dataItem(grid.select());

                if(selectedItem != null){
                    // validation qty
                    if(selectedItem.selected_qty == null || selectedItem.selected_qty == 0){
                        errorMessage = "Quantity pada item yang dipilih tidak boleh kosong";
                        this.$swal("Error", errorMessage, "error");
                    }else{
                        if(selectedItem.selected_qty > selectedItem.forcast_quantity){
                            errorMessage = "Quantity tidak boleh melebihi Limit Quantity PO";
                            this.$swal("Error", errorMessage, "error");
                        }else{
                            dataItem = proformaInvoiceServices.itemDataObject(selectedItem, selectedItem.selected_qty, this.type)
                            this.$props.saveItemProjectGrid(dataItem);
                        }
                    }
                }
                else{
                    errorMessage = "Dimohon untuk memilih item terlebih dahulu";
                    this.$swal("Error", errorMessage, "error");
                }
            }
        },
        onChangeTypeDetail() {
            var typeDetail = (this.TypeDetail == null ? null : this.TypeDetail.value);
            var po = this.PO;
            var projectId = this.ProjectId;
            var itemSchemaModel = this.ItemSchemaModel;
            var forecast_type = this.forecast_type;

            this.DataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                id : projectId,
                                po : po,
                                typeDetailId : typeDetail
                            }
                            
                            return { 
                                query: proformaInvoiceServices.readItemQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrderableItemList == null){
                            return [];
                        }else{
                            return proformaInvoiceServices.fillSelectedQuantity(response.data.GetTransactionPurchaseOrderableItemList, forecast_type);
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrderableItemList == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrderableItemList.length;
                        }
                    },
                    model: {
                        fields: itemSchemaModel
                    }
                }
            });
        }
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>