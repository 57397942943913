<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import proformaInvoiceServices from '../Script/ProformaInvoideDownPaymentScript.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';

export default {
    name: 'ProformaInvoiceDownPaymentGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'proformaInvoiceDownPaymentClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapfield = [
                                { field: "status", new_field: "\"transaction_proforma_invoice\".\"status\"" },
                                { field: "customer_name", new_field: "\"Customer\".\"contact_name\"" },
                                { field: "notes", new_field: "\"transaction_proforma_invoice\".\"notes\"" },   
                                { field: "created_by", new_field: "\"Created\".\"myname\"" },                            
                            ];
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapfield),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapfield)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: proformaInvoiceServices.readProformaInvoiceQuery(),
                                variables : variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionProformaInvoice.transaction_proforma_invoice == null){
                            return [];
                        }else{
                            return response.data.GetTransactionProformaInvoice.transaction_proforma_invoice;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionProformaInvoice.transaction_proforma_invoice == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionProformaInvoice.total;
                        }
                    },
                    model: {
                        fields: {
                            proforma_invoice_date: {type: "date"},
                            total: {type: "number"},
                            down_payment: {type: "number"},
                            down_payment_pct: {type: "number"}
                        }
                    }
                }
            }),
            columns:  [
                { title: "Action", width: 240, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 90, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "proforma_invoice_number", title: "No. PI", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "proforma_invoice_date", title: "Tanggal PI", width: 125, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(proforma_invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "total", title: "Total", width: 130, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(Math.round(total), 'n') #" },
                { field: "down_payment_pct", title: "Persentase Uang Muka (%)", width: 260, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                { field: "down_payment", title: "Uang Muka", width: 130, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: "#= kendo.toString(Math.round(down_payment), 'n') #" },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var proformaInvoiceDownPaymentClick = this.$props.proformaInvoiceDownPaymentClick;

        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.proforma_invoice_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem.proforma_invoice_id, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem.proforma_invoice_id, true);
        })

        GridElement.on("click", "#ProformaInvoiceDownPaymentButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            proformaInvoiceDownPaymentClick(dataItem.proforma_invoice_id);
        })
    },
    methods: {
        columnButton(){
            var customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-warning btn-sm rounded" id="ProformaInvoiceDownPaymentButton">
                                    <i class="fa fa-file-pdf-o"></i> </span>
                                </button>`;
            return this.$globalfunc.gridActionButton("Purchase Order", customButton)
        },
        detailInit: async function (e) {
            var data = await proformaInvoiceServices.getProformaInvoiceQuery(e.data.proforma_invoice_id);

            var itemDetail = proformaInvoiceServices.editItemGridDataGenerator(data.transaction_proforma_invoice_details);

            var totalPPNTax = 0;
            var totalGrossDisc = 0;
            var grandTotal = 0;

            if(itemDetail.length > 0){
                itemDetail.forEach(e => {
                    totalGrossDisc = totalGrossDisc + (e.net * e.qty);
                    totalPPNTax = totalPPNTax + (e.net * (data.tax_percentage / 100)) * e.qty;
                });
            }

            grandTotal = totalGrossDisc + Math.round(totalPPNTax);

            var itemMergeArray = proformaInvoiceServices.itemArray(itemDetail);
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(itemMergeArray);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return [];
                            else
                                return response;
                        },
                        total: function(response) {
                            if(response == null)
                                return 0;
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                    group: { field: "type", aggregates: [ {field: "total_price", aggregate: "sum"} ] },
                    aggregate: [{ field: "total_price", aggregate: "sum" }]
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                editable: false,
                resizable: true,
                columns: [
                    { field: "type", title: "Tipe", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                        groupHeaderTemplate: "#= value #", hidden: true},
                    { field: 'quantity', title: "Jumlah", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    { field: 'uom', title: "Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'product_code', title: "Kode Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'item_name', title: "Nama Barang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'vat_per_unit', title: "Harga", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}", attributes: { "class": "k-text-right" }, 
                        footerTemplate: `   <div style='float: right'>Total</div><br>
                                            <div style='float: right'>PPN `+ data.tax_percentage +`%</div><br>
                                            <div style='float: right'>Grand Total</div>` },
                    { field: 'total_price', title: "Total",  width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}", attributes: { "class": "k-text-right" }, 
                        aggregates: ["sum"],  footerTemplate: ` <div style='float: right'>Rp. #=  kendo.toString(Math.round(sum), 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ totalPPNTax +`, 'n0') #</div><br>
                                                                <div style='float: right'>Rp. #=  kendo.toString(`+ grandTotal +`, 'n0') #</div>` },
                ],
            });
        }
    }
}
</script>